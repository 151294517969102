import { format, getDaysInMonth } from 'date-fns'

// export const GOOGLE_MAP_API_KEY = 'AIzaSyCdw-yRVWwWV4Hfpje_ykGgiCILFp01TDs';
export const GOOGLE_MAP_API_KEY = 'AIzaSyA9TP7djtX_EfWi3AvgtKS3yWoZvf4aufY'; // SchoolBusTracer

export const DEFAULT_ERROR_MSG = "An unknown error occurred";

export const DEFAULT_MAP_LOCATION = {
    latitude: 28.612366,
    longitude: 77.231237,
};

export const SCHOOL = {
    id: null
};

export const LeftNavViews = {
    SCHOOL: "school",
    SCHOOL_ADMIN: "school_admin",
    SETTINGS: "settings",
    PROFILE: "profile",
}

export const Role = {
    SCHOOL_OWNER: "SCHOOL_OWNER",
    ADMIN: "ADMIN",
    STUDENT: "STUDENT",
    DRIVER: "DRIVER",
    PARENT: "PARENT"
}

export const OTPVerificationType = {
    REGISTER: "REGISTER",
    SCHOOL_USER: "SCHOOL_USER",
    FORGOT_PASSWORD: "FORGOT_PASSWORD"
}

export const FrequencyType = {
    RECURRING: "RECURRING",
    ONE_TIME: "ONE_TIME",
}

export const RouteType = {
    PICKUP: "PICKUP",
    DROPOFF: "DROPOFF",
}

export const URL = {
    BASE_URL: 'https://api.schoolbustracer.com',
    SOCKET_IO_URL: 'https://dispatcher.schoolbustracer.com',
    LOGSTASH_URL: 'https://api.schoolbustracer.com:5500/receive-logs',

    // SOCKET_IO_URL: 'http://localhost:5500',
    // BASE_URL: 'http://localhost:8500',
    // LOGSTASH_URL: 'http://localhost:5500/receive-logs',
    // SOCKET_IO_URL: 'http://172.100.19.114:5500',
    // BASE_URL: 'http://api.schoolbustracer.com',
}

export const IMAGES_URL = {
    SCHOOL: URL.BASE_URL + "/images/school",
    USER: URL.BASE_URL + "/images/user",
    STUDENT: URL.BASE_URL + "/images/student",
}

export const ModalTypes = {
    CONFIRM: "confirm",
    ALERT: "alert",
    ERROR: "error"
}

export const TripStatus = {
    NEW: "NEW",
    ON_RUN: "ON_RUN",
    FINISHED: "FINISHED",
    ABANDONED: "ABANDONED"
}

export const SocketEvents = {
    MY_INFO: "MyInfo",
    START_TRIP: "StartTrip",
    NOTIFICATION: 'Notification',
    UPDATE_NOTIFICATION_READ: "UpdateNotificationRead",
}

export const NotificationType = {    
    START_TRIP: "START_TRIP",
    FINISH_TRIP: "FINISH_TRIP",
    STUDENT_PICK_UP: "STUDENT_PICK_UP",
    STUDENT_DROP_OFF: "STUDENT_DROP_OFF",
    STUDENT_ABSENT: "STUDENT_ABSENT"
}

// export const NotificationType = {    
//     START_TRIP: "StartTrip",
//     FINISH_TRIP: "FinishTrip",
//     STUDENT_PICK_UP: "StudentPickUp",
//     STUDENT_DROP_OFF: "StudentDropOff",
// }

export const ImageSourceType = {
    LOCAL: 'LOCAL',
    REMOTE: 'REMOTE',
}

export const ModalType = {
    CONFIRM_DELETE: 'CONFIRM_DELETE',
    CONFIRM_DISABLE: 'CONFIRM_DISABLE',
    CONFIRM_FORM: 'CONFIRM_FORM',
    ALERT: 'ALERT',
}

export const TripStudentStatus = {
    NEW: 'NEW',
    PICKED_UP: 'PICKED_UP',
    DROPPED_OFF: 'DROPPED_OFF',
    ABSENT: 'ABSENT'
}

// theme constant
export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;


export function convertRole(role) {
    if (role === 'SCHOOL_OWNER') {
        return 'School Owner';
    } 
    else if (role === 'ADMIN') {
        return 'Admin';
    }
    else if (role === 'STUDENT') {
        return 'Student';
    }
    else if (role === 'DRIVER') {
        return 'Driver';
    }
    else if (role === 'PARENT') {
        return 'Parent';
    }
}

export function convertTripStudentStatus(status) {
    if (status === TripStudentStatus.PICKED_UP) {
        return 'Got on the bus';
    } else if (status === TripStudentStatus.DROPPED_OFF) {
        return 'Got off the bus';
    } if (status === TripStudentStatus.ABSENT) {
        return 'Absent';
    }
}

export function convertFrequencyType(type) {
    if (type === FrequencyType.RECURRING) {
        return 'Recurring';
    } 
    else if (type === FrequencyType.ONE_TIME) {
        return 'One Time';
    }
}

export function convertFrequency(freq) {
    if (freq === 'SUN') {
        return 'Sunday';
    } 
    else if (freq === 'MON') {
        return 'Monday';
    }
    else if (freq === 'TUE') {
        return 'Tuesday';
    }
    else if (freq === 'WED') {
        return 'Wednesday';
    }
    else if (freq === 'THU') {
        return 'Thursday';
    }
    else if (freq === 'FRI') {
        return 'Friday';
    }
    else if (freq === 'SAT') {
        return 'Saturday';
    }
}

export function convertRouteType(type) {
    if (type === 'PICKUP') {
        return 'Pickup';
    } else if (type === 'DROPOFF') {
        return 'Drop off';
    }
}

export function convertTripStatus(status) {
    if (status === TripStatus.NEW) {
        return 'New'
    }
    else if (status === TripStatus.ON_RUN) {
        return 'On Run'
    }
    else if (status === TripStatus.FINISHED) {
        return 'Finished'
    }
    else if (status === TripStatus.ABANDONED) {
        return 'Abandoned'
    }
}

export function convertMillisToTimeFormat(millis) {
    var date = new Date(millis);
    return format(date, 'hh:mm a')
}

export function convertMillisToDateTimeFormat(millis) {
    var date = new Date(millis);
    return format(date, 'yyyy-MM-dd, hh:mm a')
}

export function formatDateTime(dateStr) {
    if (!dateStr || dateStr.trim() === '') {
        return null;
    }
    var date = new Date(dateStr);
    return format(date, 'yyyy-MM-dd, hh:mm a')
}
export function formatDateTimeRev(dateStr) {
    if (!dateStr || dateStr.trim() === '') {
        return null;
    }
    var date = new Date(dateStr);
    return format(date, 'hh:mm a, dd-MM-yyyy')
}

export function formaTime(dateStr) {
    if (!dateStr || dateStr.trim() === '') {
        return null;
    }
    var date = new Date(dateStr);
    return format(date, 'hh:mm a')
}

export function convertLatLngToCommaSeparated(latLng) {
    return`${latLng.lat},${latLng.lng}`;
}

export function convertLatLngsToPipeSeparated(latLngs) {
    let pipeSeparated = '';
    latLngs.forEach((item, index) => {
        pipeSeparated += `|${convertLatLngToCommaSeparated(item)}`;
    });
    if (pipeSeparated.indexOf('/')) {
        pipeSeparated = pipeSeparated.substr(1);
    }
    return pipeSeparated;
}

// Convert Meters to Kms text
export function convertMetersToKMsText(meters) {
    if (!meters || meters === 0) {
        return 'N/A';
    }
    if (meters < 1000) {
        return `${meters} meters`;
    }
    return `${(meters/1000).toFixed(1)} kms`;
}

// Convert seconds to hours Text - include seconds
export function convertSecsToHms(secs) {
    secs = Number(secs);
    return new Date(secs * 1000).toISOString().substring(11, 19);
    // var h = Math.floor(secs / 3600);
    // var m = Math.floor(secs % 3600 / 60);
    // var s = Math.floor(secs % 3600 % 60);

    // var hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
    // var mDisplay = m > 0 ? m + (m === 1 ? " minute, " : " minutes, ") : "";
    // var sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";
    // return hDisplay + mDisplay + sDisplay; 
}

// Convert seconds to hours Text - exclude seconds
export function convertSecsToHm(secs) {
    secs = Number(secs);
    var h = Math.floor(secs / 3600);
    var m = Math.floor(secs % 3600 / 60);
    var s = Math.floor(secs % 3600 % 60);

    var hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m === 1 ? " minute, " : " minutes ") : "";
    return hDisplay + mDisplay; 
}

export function buildRouteStudent(routeType, student) {
    let location = null;
    if (routeType === 'DROPOFF') {
        location = student.dropoff;
    } else {
        location = student.pickup;
    }

    const newStudent = {
        id: student.id,
        name: student.name,
        latitude: location && parseFloat(location.latitude),
        longitude: location && parseFloat(location.longitude),
        location: location && location.location,
        rollNo: student.roll_no,
        sclass: student.sclass,
        section: student.section && student.section,
        profilePhoto: student.profile_photo
    }
    console.log(`Built route student - ${JSON.stringify(newStudent)}`);
    return newStudent;
}

export function convertTimeToAMPM(time) {
    // Check if time is null or blank
    if (!time) {
        return '';
    }

    // Split the time string into its components
    let [hours, minutes, seconds] = time.split(':');

    // Convert hours to a number
    hours = parseInt(hours, 10);

    // Determine AM or PM
    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'

    // Ensure minutes are always two digits
    minutes = minutes.padStart(2, '0');

    // Return the formatted time without seconds
    return `${hours}:${minutes} ${ampm}`;
}

export const convert24HourTo12Hour = (time) => {
    const [hourString, minuteString] = time.split(':');
    let hours = parseInt(hourString, 10);
    const minutes = minuteString.slice(0, 2); // Ensure we get only the minutes part
    const period = hours >= 12 ? 'PM' : 'AM';

    if (hours === 0) {
        hours = 12; // Midnight edge case
    } else if (hours > 12) {
        hours -= 12;
    }

    return {
        hours: hours.toString().padStart(2, '0'),
        minutes: minutes,
        period: period
    };
};

export const getErrorMsg = (err) => {
    if (!err) {
        return DEFAULT_ERROR_MSG; // Fallback if err is null or undefined
    }

    if (typeof err === "string") {
        return err; // If err is a plain string, return it directly
    }

    if (typeof err === "object" && err.message) {
        return err.message; // If err is an object with a message property, return the message
    }

    return DEFAULT_ERROR_MSG; // Fallback for other cases
};


export const convertTimeObjToDate = (timeObj) => {
    const hours = parseInt(timeObj.hours, 10);
    const minutes = parseInt(timeObj.minutes, 10);
    const period = timeObj.period;
    
    // Convert to 24-hour format
    let hours24 = hours;
    if (period === 'PM' && hours !== 12) {
        hours24 += 12;
    } else if (period === 'AM' && hours === 12) {
        hours24 = 0;
    }

    // Create a Date object with today's date and the time
    const today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), today.getDate(), hours24, minutes);
};
