import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from 'react';
import { Box } from '@mui/material';
import GoogleMapReact from 'google-map-react';

import { reverseGeocode, snapToRoads } from '../../services/http.service';
import { URL, GOOGLE_MAP_API_KEY } from '../../utils/constants';
import config from '../../config';


const TripReplayGoogleMap = (props, ref) => {
    const [initialCenter, setInitialCenter] = useState({ lat: 40.854885, lng: -88.081807 });
    const [center, setCenter] = useState({ lat: 40.854885, lng: -88.081807 });
    const [zoom, setZoom] = useState(14);
    const [markers, setMarkers] = useState([]);
    const [infoWindow, setInfoWindow] = useState(null);
    const [polyline, setPolyline] = useState(null);
    const [locations, setLocations] = useState([]);

    const mapRef = useRef();
    const mapsRef = useRef();
    const busMarkerRef = useRef();

    useEffect(() => {
        console.log("props.locations", props.locations);
        if (props.locations?.length > 0) {
            setLocations(props.locations);
        }
    }, [props.locations]);

    const onMapReady = (map, maps) => {
        console.log('on map ready')
        mapRef.current = map;
        mapsRef.current = maps;
        // send back to parent - to call functions when maps object loaded
        if (props.onMap) {
            props.onMap(map);
        }
        if (props.onMaps) {
            props.onMaps(maps);
        }
        drawPathOnMap();
    };

     // function calls from parent ---------------
     useImperativeHandle(ref, () => ({
        drawPath(locations) {
            //drawOnMap(students);
        },
        moveBusOnMap(location) {
            moveBusOnMap(location);
        }
    }), []);
    // End ---------------------------------------

    // Draw path on map
    const drawPathOnMap = () => {
        let latlngs = [];
        let bounds = new mapsRef.current.LatLngBounds();

        locations.forEach(item => {
            let latLng = {
                lat: item.latitude,
                lng: item.longitude
            };
            bounds.extend(latLng);
            latlngs.push(latLng);
        });
        console.log("latlngs ", latlngs);
        // Fit/Zoom all the markers
        mapRef.current.fitBounds(bounds);

        const tripPolyline = new mapsRef.current.Polyline({
            path: latlngs,
            geodesic: true,
            strokeColor: '#3365b7',
            strokeOpacity: 1.0,
            strokeWeight: 3
        });
        tripPolyline.setMap(mapRef.current);
    }


        // Move bus on map along the location coordinates
    const moveBusOnMap = (location) => {
        console.log(`moveBusOnMap - ${JSON.stringify(location)}`);

        let latLng = {
            lat: parseFloat(location.latitude),
            lng: parseFloat(location.longitude)
        };
        // this.setState({ busMarkerPosition: latLng });

        if (busMarkerRef.current) {
            busMarkerRef.current.setMap(null);
            busMarkerRef.current = null;
        }
        let marker = new mapsRef.current.Marker({
            draggable: false,
            position: latLng,
            map: mapRef.current,
            icon: `${config.BASE_URL}/icons/icon_bus.png`
        });
        busMarkerRef.current = marker;
    }


    return (
        <>
            <div className="route-map-container">
                {locations.length &&
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: GOOGLE_MAP_API_KEY, libraries: ['places', 'geometry'], }}
                        defaultCenter={center}
                        defaultZoom={zoom}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({ map, maps }) => onMapReady(map, maps)}
                    />
                }
            </div>
        </>
    );
}

// class TripReplayGoogleMap extends Component {
//     constructor(props) {
// 		super(props)
// 		this.state = {
//             initialCenter: { lat: 40.854885, lng: -88.081807 },
//             center: { lat: 40.854885, lng: -88.081807 },
//             zoom: 14,
//             map: null,
//             markers: [],
//             infoWindow: null,
//             polyline: null,
//             busMarker: null,
//             busMarkerPosition: null,
//             locations: [],
//         }
//     }

//     onMapReady = (mapProps, map) => {
//         console.log('on map ready');  
//         this.setState({ map });
        
//         this.drawPathOnMap();
//     };

//     // Draw path on map
//     drawPath = (locations) => {        
//         this.setState({ locations });     
//     }

//     // Draw path on map
//     drawPathOnMap = () => {
//         const { google } = this.props;
//         let { locations, map, polyline, markers } = this.state;
//         let latlngs = [];
//         //let snapToRoadsPath = '';
//         let bounds = new google.maps.LatLngBounds();

//         console.log(`drawPathOnMap - ${JSON.stringify(locations)}`);

//         locations.forEach(item => {
//             let latLng = {
//                 lat: item.latitude,
//                 lng: item.longitude
//             };
//             bounds.extend(latLng);
//             latlngs.push(latLng);
//             //snapToRoadsPath += `${item.latitude},${item.longitude}|`;
//         });
//         // Fit/Zoom all the markers
//         map.fitBounds(bounds);

//         //console.log(`Polyline draw path - ${JSON.stringify(latlngs)}`);

//         //latlngs = latlngs.slice(0, 106);

//         console.log(`Polyline draw path - ${JSON.stringify(latlngs)}`);
//         //snapToRoadsPath = snapToRoadsPath.substring(0, snapToRoadsPath.length - 1);
//         // Get snap to roads
//         // snapToRoads(snapToRoadsPath).then(resp => {
//         //     console.log(`Response of snap to path - ${JSON.stringify(resp.data)}`);
//         //     if (resp.status === 200) {
//         //         const path = resp.data.snappedPoints.map(item => {
//         //             return {
//         //                 lat: item.location.latitude,
//         //                 lng: item.location.longitude
//         //             }
//         //         });
//         //         console.log(`Polyline draw path - ${JSON.stringify(path)}`);
//         //          polyline = new google.maps.Polyline({
//         //             path: path,
//         //             map: map,
//         //             geodesic: true,
//         //             strokeColor: '#3365b7',
//         //             strokeOpacity: 1.0,
//         //             strokeWeight: 3
//         //         });
//         //     }
//         // }).catch(err => {
//         //     console.log(`Error in snap to path - ${err}`);
//         // })


//         // Draw Polyline of route	        
//         polyline = new google.maps.Polyline({
//             path: latlngs,
//             map: map,
//             geodesic: true,
//             strokeColor: '#3365b7',
//             strokeOpacity: 1.0,
//             strokeWeight: 3
//         });
//         //polyline.setMap(map);
//         //this.setState({ polyline });
//     }

//     // Move bus on map along the location coordinates
//     moveBusOnMap = (location) => {
//         console.log(`moveBusOnMap - ${JSON.stringify(location)}`);
//         const { google } = this.props;
//         const { map, busMarker } = this.state;

//         let latLng = {
//             lat: parseFloat(location.latitude),
//             lng: parseFloat(location.longitude)
//         };
//         // this.setState({ busMarkerPosition: latLng });
        

//         if (busMarker) {
//             busMarker.setMap(null);
//             this.setState({ busMarker: null });
//         }

     

//         let marker = new google.maps.Marker({
//             draggable: false,
//             position: latLng,
//             map: map,
//             icon: `${URL.BASE_URL}/icons/icon_bus.png`
//         });
//         this.setState({ busMarker: marker });
//     }

    

//     render() {

//         const { locations, busMarkerPosition } = this.state;

//         console.log(`render locations - ${locations.length}`);

//         return (
//             <React.Fragment>
//                 {/* {locations.length > 0 &&
//                     <Map google={this.props.google}
//                         initialCenter={this.state.initialCenter}
//                         center={this.state.center}
//                         zoom={this.state.zoom}
//                         onReady={this.onMapReady}>
//                     </Map> 
//                 } */}
//             </React.Fragment>         
//         )
//     }

// }

export default forwardRef(TripReplayGoogleMap); // 'forwardRef' enables call function from parent